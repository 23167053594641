export default {
  setUser(state, payload) {
    state = Object.assign(state, payload);
    // state.token = payload.token || null;
    state.userID = payload.id;
    // state.role = payload.role;
    // state.phone = payload.phone;
    // state.phoneCode = payload.phoneCode;
    // state.firstName = payload.firstName;
    // state.lastName = payload.lastName;
    // state.email = payload.email;
    // state.company = payload.company || null;
    // state.image = payload.image || null;
    // state.autoLogout = false;
    // state.paymentMethod = payload.paymentMethod || null;
    // state.isVerified = payload.isVerified;
  },
  toggleChat(state) {
    state.loadChat = !state.loadChat;
  },

  setAutoLogout(state) {
    state.autoLogout = true;
  },
  updateDetails(state, payload) {
    state.firstName = payload;
  },
  setCompany(state, payload) {
    state.company = payload.company;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
};
