import ApiService from "@/core/services/ApiService";

import store from "../store";

const activeSubscription = async (to, from, next) => {
  /* eslint-disable */
  const company = store.getters["company/getCompany"];
  console.log(company)
  let currOverallSubsStatus= false;
  let overallSubsStatus= false;

  if (!company._id) {
    console.log("yes working");
    const user_id = localStorage.getItem("userID");
    const result = await ApiService.get("company/query?registrar=" + user_id);
    const currentCompany = result?.data?.data[0];

    try{
      const currSubscriptionsStatuses = currentCompany?.allSubscriptions.map((e)=>e.status);
      currOverallSubsStatus = currSubscriptionsStatuses?.some((val)=>val=='active');

    }
    catch(err){
      console.log(err);

    }
    try{
      const subscriptionsStatuses = company?.allSubscriptions.map((e)=>e.status);
      overallSubsStatus = subscriptionsStatuses?.some((val)=>val=='active');
      console.log(overallSubsStatus);

    }
    catch(err){
      console.log(err);

    }
    if (
      !currentCompany.subscription?.id ||
      currOverallSubsStatus
    ) {
      next("/billing");
      return;
    }
  } else if (
    !company?.subscription?.id  || overallSubsStatus  ) {
    next("/billing");
    return;
  }

  next();
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: activeSubscription,
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("@/views/Documents.vue"),
        beforeEnter: activeSubscription,
      },
      {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/Billing.vue"),
      },
      {
        path: "/company",
        redirect: "/company/overview",
        name: "profile",
        component: () => import("@/views/layouts/Company.vue"),
        beforeEnter: activeSubscription,
        children: [
          {
            path: "overview",
            name: "company-overview",
            component: () => import("@/views/company/Overview.vue"),
          },
          {
            path: "shareholders",
            name: "company-shareholders",
            component: () => import("@/views/company/Shareholders.vue"),
          },
          {
            path: "services",
            name: "company-services",
            component: () => import("@/views/company/Services.vue"),
          },
          {
            path: "edit",
            name: "company-Edit",
            component: () => import("@/views/company/Settings.vue"),
          },
        ],
      },

      {
        path: "/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        beforeEnter: activeSubscription,
        children: [
          {
            path: "profile",
            name: "account-profile",
            component: () => import("@/views/crafted/account/Profile.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
    ],
    beforeEnter: (to, from, next) => {
      const first = store.getters["auth/isAuthenticated"];
      if (!first) {
        next("/sign-in");
        return;
      }
      store.dispatch("auth/hasExpired").then((response) => {
        if (response) {
          next("/sign-in");
          return;
        }
      });
      next();
    },
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        beforeEnter: (to, from, next) => {
          const first = store.getters["auth/isAuthenticated"];
          if (first) {
            next("/dashboard");
            return;
          }
          store.dispatch("auth/hasExpired").then((response) => {
            if (!response) {
              next("/dashboard");
              return;
            }
          });
          next();
        },
      },
      {
        path: "/sign-up",
        redirect: "/sign-in",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        beforeEnter: (to, from, next) => {
          const first = store.getters["auth/isAuthenticated"];
          if (first) {
            next("/dashboard");
            return;
          }
          store.dispatch("auth/hasExpired").then((response) => {
            if (!response) {
              next("/dashboard");
              return;
            }
          });
          next();
        },
      },
      {
        path: "/verify",
        name: "Verify",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Verify.vue"),
      },
      {
        path: "/verify/service",
        name: "VerifyPayment",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/VerifyPayment.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routes;
