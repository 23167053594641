export default function () {
  return {
    _id: null,
    active: false,
    address_city: null,
    address_country: null,
    address_state: null,
    address_street: null,
    address_zipcode: null,
    business_address: null,
    certificate_of_incorporation: null,
    companyName: null,
    companyType: null,
    company_filing: null,
    company_house_hmrc_letter: null,
    createdAt: null,
    deactivated: false,
    eori: null,
    industry: null,
    initialPayment: null,
    logo: null,
    mailing_address: null,
    memorandum_association_articles: null,
    pob: null,
    registrar: null,
    share_certificates: null,
    updatedAt: null,
    website: null,
    subscription: null,
  };
}
