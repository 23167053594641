import ApiService from "@/core/services/ApiService";

let ip;
if (process.env.DEV) {
  ip = process.env.VUE_APP_API_URL;
} else {
  ip = process.env.VUE_APP_API_URL;
}
export default {
  async getPackage(context) {
    let url = `${ip}/api/package`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      let error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    context.commit("setCompany", responseData.company || {});

    return responseData;
  },
  async postSignatureDocument(context, payload) {
    let url = `${ip}/api/documents/signature`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async postDocument(context, payload) {
    let url = `${ip}/api/documents`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async buyService(context, payload) {
    let url = `${ip}/api/company/buy-service`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async confirmService(context, payload) {
    let url = `${ip}/api/company/confirm-service`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async deactivateCompany(context, payload) {
    let url = `${ip}/api/deactivate/company`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getSummary() {
    let url = `${ip}/api/summary`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getDocuments() {
    let url = `${ip}/api/documents`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getShareholders() {
    let url = `${ip}/api/shareholders`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getServices() {
    let url = `${ip}/api/services`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getCompany(context) {
    const user_id = localStorage.getItem("userID");
    if (!user_id) return;
    const result = await ApiService.get("company/query?registrar=" + user_id);
    if (result.data?.data?.length) {
      context.commit("setCompany", { ...result.data?.data[0] });
    }
    return;
  },
  async update(context, payload) {
    context.commit("setCompany", { ...payload });
  },
};
